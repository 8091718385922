// DashboardPage.jsx
import React, { useState, useEffect } from "react";
import { Table, Layout, Space, Typography,  Button, Flex } from "antd";
import type { TableColumnsType } from 'antd';



const { Content } = Layout;
const { Title } = Typography;

const DashboardPage = () => {
  const [moduleData, setModuleData] = useState <any>([]);
  const [apiData, setApiData] = useState <any>([]);

  // Mock data for the module store
  useEffect(() => {
    const fetchData = async () => {
      // This is where you would fetch the data from your backend
      // const response = await fetch('/api/module-store');
      // const result = await response.json();
      // setData(result);

      // Mock data for now
      const mockData = [
        {
          key: '1',
          name: 'Module 1',
          description: 'Description of Module 1',
          version: '1.0.0',
        },
        {
          key: '2',
          name: 'Module 2',
          description: 'Description of Module 2',
          version: '1.2.0',
        },
        {
          key: '3',
          name: 'Module 3',
          description: 'Description of Module 3',
          version: '2.0.0',
        },
      ]

      const mockData2 = [
          {
            key: '1',
            product_name: 'Api Key {product_name}',
            description: 'Description of {product_name}',
            allowed_routes: ["/businessunit","/deliverables"],
            created_at: 'yesterday',
            expires_at: '5193-06-08T12:56:45',
          },
          {
            key: '2',
            product_name: 'Api Key {product_name}',
            description: 'Description of {product_name}',
            allowed_routes: ["/check"],
            created_at: '2024-07-24T03:10:06',
            expires_at: '5193-06-08T12:56:45',
          },
          {
            key: '3',
            product_name: 'Api Key {product_name}',
            description: 'Description of {product_name}',
            allowed_routes: ["test,test2"],
            created_at: '2024-07-23T15:03:14',
            expires_at: '5193-06-08T00:49:53',
          },
      ];
      setModuleData(mockData);
      setApiData(mockData2)
    };

    fetchData();
  }, []);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Version',
      dataIndex: 'version',
      key: 'version',
    },
  ];

  const apiKeyColumns: TableColumnsType<any> = [
    {
      title: 'Product_name',
      dataIndex: 'product_name',
      key: 'product_name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Allowed_routes',
      dataIndex: 'allowed_routes',
      key: 'allowed_routes',
    },
    {
      title: 'Created_at',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: 'Expires_at',
      dataIndex: 'expires_at',
      key: 'expires_at',
    },
    {
      title: 'Action',
      key: 'operation',
      fixed: 'right',
      width: 100,
      render: () => <Button>Update</Button>,
    }
  ];

  return (
    <Layout>
      <Content style={{ padding: '50px' }}>
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <div className="button-container">
            <Title level={2}>Module Store</Title>
            <Button>Add</Button>
          </div>
          <Table columns={columns} dataSource={moduleData} />
        </Space>
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <div className="button-container">
            <Title level={2}>Api Key Store</Title>
            <Button>Add</Button>
          </div>
          <Table columns={apiKeyColumns} dataSource={apiData}/>
        </Space>
      </Content>
    </Layout>
  );
};

export default DashboardPage;
