import React, { useEffect, useMemo, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
	Outlet,
} from "react-router-dom";
import { Alert, ConfigProvider, theme } from "antd";
import Home from "./containers/Home";
import Login from "./containers/Login";
import DashboardPage from "./containers/DashboardPage";
import Location from "./utils/location";

const { defaultAlgorithm, darkAlgorithm } = theme;

function App() {
	let isLogin = true;
	let shouldRedirect = false;
	let currentDate: any = Date.now() / 1000;
	let timeout: any = null;

	const [alert, setAlert] = useState<any>(null);
	const [currentLocation, setCurrentLocation] = useState<any>(
		window.location.pathname
	);

	const onLocationChange = (location: Location) => {
		console.log(location);
		setCurrentLocation(location.pathname);
	};

	return (
		<ConfigProvider theme={{ hashed: false, algorithm: defaultAlgorithm }}>
			<div className="main-page">
				<Router>
					<Location onChange={onLocationChange}></Location>
					{shouldRedirect ? <Navigate to="" /> : <></>}
					<Routes>
						{/* <Route path="" element={<Login />} /> */}
						{isLogin ? (
							<>
								{" "}
								<Route
									path=""

									element={<DashboardPage />}
								>
				
									{/* {menuRoutes.map((menuItem: any) => {
										return menuItem.route;
									})}
									{adminMenuRoutes.map((menuItem: any) => {
										return menuItem.route;
									})} */}
								</Route>
								<Route path="dashboard" element={<DashboardPage />}></Route>
							</>
						) : (
							<></>
						)}
					</Routes>
				</Router>
				{alert && (
					// <Alert
					// 	className={
					// 		alert?.top ? "alert-message-box-top" : "alert-message-box"
					// 	}
					// 	type={alert?.type}
					// 	message={alert?.message}
					// 	description={alert?.description}
					// 	showIcon
					// 	closable={alert?.closable || false}
					// 	afterClose={() => setAlert(null)}
					// 	style={{
					// 		fontFamily: "Open Sans,sans-serif, arial",
					// 		...getAlertStyle(alert?.type),
					// 	}}
					// />
					<></>
				)}
			</div>
		</ConfigProvider>
	);
}

export default App;
